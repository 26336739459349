import {Route, Routes} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {ViewProof} from "./view/ViewProof";
import "./App.css"

/**
 * Main Viewer app that is at the base url for public content.
 * The actual path determines the file that is retrieved and viewed.
 * @returns {JSX.Element}
 * @constructor
 */
export default function App() {
    return <MyRoutes/>
}

/**
 * Defines all application routes in one place.
 */
function MyRoutes()
{
    return <Routes>
        {/*<Route element={<Layout/>}>*/}
            <Route index element={<HomePanel/>}/>
            <Route path="/:systemId/proof/:proofId" element={<ViewProof />}/>
            <Route path="*" element={<HomePanel/>}/>
        {/*</Route>*/}
    </Routes>
}


function HomePanel()
{
    return <div>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <div>
                <Typography fontSize="larger">404 Not Found</Typography>
                <div>
                    <p>If you got here, then the file you were trying to view can not be found.</p>
                    <p>如果您来到了这里，那么您想查看的文件找不到了。</p>
                    <p>Si llegaste aquí, entonces el archivo que intentabas ver no puede ser encontrado.</p>
                    <p align="right">ا وصلت إلى هنا، فإن الملف الذي كنت تحاول عرضه لا يمكن العثور عليه. </p>
                    <p>Se você chegou aqui, então o arquivo que você estava tentando visualizar não pode ser encontrado.</p>
                    <p>যদি আপনি এখানে এসেছেন তবে আপনি যে ফাইলটি দেখতে চেষ্টা করছিলেন তা খুঁজে পাওয়া যায় নি।</p>
                    <p>Если вы попали сюда, то файл, который вы пытались просмотреть, не может быть найден.</p>
                    <p>ここに来たのであれば、閲覧しようとしたファイルは見つかりません。</p>
                </div>
            </div>
        </Box>
    </div>
}


