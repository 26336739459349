import Big from "big.js";
import {AmountFormat} from "@trovio-tech/trovio-core-api-jsx";

/**
 * Render an amount of product using the configuration for that product.
 * @param product
 * @param productId
 * @param amount
 * @param unassignedAmount
 * @param assignedAmount
 * @param issuerAmount
 * @constructor
 */
export function ProductAmount( {productData, amount, minDecimalPos, maxDecimalPos, fixedWidth=true } )
{
    let bigAmount= Big(amount)
    let actualMinDecimalPos= minDecimalPos || productData.minDecimalPos;
    let actualMaxDecimalPos= maxDecimalPos || productData.maxDecimalPos;

    return <AmountFormat amount={bigAmount} minDecimalPos={actualMinDecimalPos} maxDecimalPos={actualMaxDecimalPos} fixedWidth={fixedWidth}/>
}

