import {createTheme} from "@mui/material/styles";
import {darken, lighten} from "@mui/material";

const trovioDark='#1d201c'
const trovioDarker='#151815'
const trovioOrange='#f8834d'
const trovioBeige='#e2e8c0'
// const trovioBlue='#9ba7c0'
// const trovioLavender='#cbc5ea'
const trovioGreen='#71b771'
// const trovioTeal='#a0d1c8'

export const defaultTheme=createTheme( {
    name: "default",
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
                sx: {m: 1}
            },
        },
        MuiAppBar: {
            defaultProps: {
                variant: 'elevation',
                color: 'default'
            },
        }
    },
    appTitle: {
        color: trovioDark,
        fontSize: 24,
        lineHeight: '1.5',
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        text: "CorTenX"
    },
    appImage: "/logo_trovio_dark.png"
} )

export const trovioDarkTheme=createTheme( {
    name: "trovio-dark",
    palette: {
        mode: 'dark',
        primary: {
            main: trovioOrange,
        },
        secondary: {
            main: trovioBeige,
        },
        background: {
            default: trovioDark,
            paper: trovioDarker,
        },
        text: {
            primary: trovioBeige,
        },
        success: {
            main: trovioGreen,
        },
    },
    typography: {
        fontFamily: 'PPPangramSans, Roboto, Arial',
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
                sx: {m: 1}
            },
        },
    },
    appTitle: {
        color: trovioBeige,
        fontSize: 24,
        lineHeight: '1.5',
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        text: "CorTenX"
    },
    amountFormat: {
        digitWidth: '1.2ex',
        decimalPointWidth: '0.5ex'
    }
} )

export const trovioLightTheme=createTheme( {
    name: "trovio-light",
    palette: {
        mode: 'light',
        primary: {
            main: trovioOrange,
        },
        secondary: {
            main: trovioDark,
        },
        background: {
            default: lighten( trovioBeige, 0.8 ),
            paper: lighten( trovioBeige, 0.7 ),
        },
        text: {
            primary: trovioDark,
            secondary: trovioDarker,
            disabled: trovioDark,
            hint: trovioDarker
        },
        success: {
            main: trovioGreen,
        },
    },
    typography: {
        fontFamily: 'PPPangramSans, Roboto, Arial',
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
                sx: {m: 1}
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'filled'
            }
        },
        MuiAppBar: {
            defaultProps: {
                variant: 'elevation',
                color: 'secondary'
            },
            styleOverrides: {
                colorSecondary: {
                    backgroundColor: trovioBeige
                }
            }
        }
    },
    appTitle: {
        color: trovioDark,
        fontSize: 24,
        lineHeight: '1.5',
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        text: "CorTenX"
    },
    appImage: "/logo_trovio_dark.png",
    amountFormat: {
        digitWidth: '1.2ex',
        decimalPointWidth: '0.5ex'
    }
} )

const white= '#ffffff'
const cms1= '#281B39'
const cms2= '#2F147A'
// const cms3= '#4720B7'
const cms4= '#40C0F2'
const cms5= '#70F0A6'
// const cms6= '#B0F0CB'
// const cms7= '#D1F8E0'

export const cmsTheme=createTheme( {
    name: "cms",
    palette: {
        mode: 'light',
        primary: {
            main: darken( cms4, 0.2 ),
        },
        secondary: {
            main: cms2,
        },
        background: {
            paper: '#ffffff',
            default: lighten( cms4, 0.9 ),
        },
        text: {
            primary: cms1,
        },
        success: {
            main: cms5,
        },
    },
    typography: {
        fontFamily: 'PPPangramSans, Roboto, Arial',
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                sx: {m: 1}
            },
        },
        MuiAppBar: {
            defaultProps: {
                variant: 'elevation',
                color: 'secondary'
            },
            styleOverrides: {
            }
        }
    },
    appTitle: {
        color: white,
        fontSize: 24,
        lineHeight: '1.5',
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        text: 'CorTenX'
    },
    appImage: "/logo_cms.png",

    amountFormat: {
        digitWidth: '1.2ex',
        decimalPointWidth: '0.5ex'
    }
} )

/**
 * Themes organized by name
 * @type {{material: Theme, cms: Theme, trovioDark: Theme, trovioLight: Theme}}
 */
export const appThemes= {
    "cms": cmsTheme,
    "trovio-dark": trovioDarkTheme,
    "trovio-light": trovioLightTheme,
    "material": defaultTheme
}
