import {Navigate, useLocation, useParams, useSearchParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {
    Alert,
    AppBar,
    Box,
    Button,
    Chip,
    CssBaseline,
    Grid,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {faCopy} from "@fortawesome/free-regular-svg-icons/faCopy";
import {ThemeProvider} from "@emotion/react";
import {appThemes, cmsTheme, defaultTheme, trovioDarkTheme, trovioLightTheme} from "../Themes";
import {Fragment, useState} from "react";
import {ProductAmount} from "../util/ProductAmount";
import Big from "big.js";
import {styled} from "@mui/material/styles";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {faFilePdf} from "@fortawesome/free-regular-svg-icons/faFilePdf";
import {faFileImage} from "@fortawesome/free-regular-svg-icons/faFileImage";
import {faFile} from "@fortawesome/free-regular-svg-icons/faFile";
import {faPalette} from "@fortawesome/free-solid-svg-icons/faPalette";

/**
 * View a proof with all details. The page verifies the proof when displaying and allows the raw proof data to be downloaded.
 * @returns {JSX.Element}
 * @constructor
 */
export function ViewProof()
{
    const { systemId }= useParams()
    const url= useLocation()

    // get a theme from the search parameters
    const [ searchParams ]= useSearchParams()
    var themeName= searchParams.get( "theme" )
    if( !themeName || !appThemes[ themeName ] )
    {
        themeName= "trovio-dark"
    }

    const [theme, setTheme]= useState( appThemes[ themeName ] )
    const [themeAnchorEl, setThemeAnchorEl] = useState();

    function selectTheme( t )
    {
        setTheme( t )
        setThemeAnchorEl()
    }

    // the path to our source document
    const docPath= url.pathname.endsWith( ".json" )? url.pathname: (url.pathname + ".json")

    const [{data: content, loading, error}]=useAxios( {
        url: docPath,
        headers: {
            Accept: "application/json"
        }
    }, {useCache: true} )

    if( loading ) return <LinearProgress/>
    if( error ) return <Navigate to="/"/>

    const data= content.proof
    if( !content || !data || !data.transaction ) return <Navigate to="/"/>

    const appBarHeight= 4;

    const bakedHoldings= (data.transaction.bakedHoldings && data.transaction.bakedHoldings.length>0)? organizeHoldings( content, data.transaction.bakedHoldings ): undefined

    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Box sx={{width:'100%', m:1}}>

            <AppBar position="fixed" sx={{zIndex:(theme)=>theme.zIndex.drawer+1}}>
                <Toolbar variant="dense" >
                    <Grid container justifyContent="space-between" width="100%">
                        <Grid item alignItems="baseline" >
                            <img src={theme.appImage || "/logo_trovio_light.png"} height={40} style={{paddingTop:'10px'}}  alt="Trovio"></img>
                        </Grid>
                        <Grid item alignContent="space-evenly">
                            <Typography variant="h6" noWrap component="div" sx={theme.appTitle} style={{paddingTop:'15px'}}>
                                &nbsp;&nbsp;CorTenX Transaction Proof
                            </Typography>
                        </Grid>
                        <Grid item >
                        </Grid>
                        <Grid item xs={true}>
                        </Grid>
                        <Grid item alignSelf="flex-start" className="not-in-print">
                            <div>
                                <Button startIcon={<FontAwesomeIcon icon={faDownload}/>} href={docPath}>Download</Button>
                                <Button startIcon={<FontAwesomeIcon icon={faPlay}/>}>Verify</Button>
                                {/*<small>{accessKey.currentKey?.id}</small>*/}
                                <IconButton onClick={(e)=>setThemeAnchorEl(e.currentTarget)} color="primary">
                                    <Tooltip title="Select theme">
                                        <div> <FontAwesomeIcon icon={faPalette} /> </div>
                                    </Tooltip>
                                </IconButton>
                                <Menu open={Boolean(themeAnchorEl)} anchorEl={themeAnchorEl}
                                      onClose={()=>setThemeAnchorEl()}
                                      sx={{zIndex:(theme)=>theme.zIndex.drawer+2}}>
                                    <MenuItem onClick={() => selectTheme(trovioDarkTheme)}>Dark</MenuItem>
                                    <MenuItem onClick={() => selectTheme(trovioLightTheme)}>Beige</MenuItem>
                                    <MenuItem onClick={() => selectTheme(cmsTheme)}>CMS</MenuItem>
                                    <MenuItem onClick={() => selectTheme(defaultTheme)}>Default</MenuItem>
                                </Menu>
                            </div>
                        </Grid>
                   </Grid>
                </Toolbar>
            </AppBar>

            <Box component="main" sx={{width:'100%', m:1}}>
                <Box sx={{paddingTop:appBarHeight}}>&nbsp;</Box>
                <Grid container p={2}>
                    <Grid item xs={12}>
                        <div className="print-only">
                            <Alert severity="info" >
                                <small>
                                    The contents of this document are available at the following url:<br/>
                                    <a href={ url.pathname}>{window.location.href}</a>
                                </small>
                            </Alert>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>System ID:</TableCell>
                                    <TableCell>
                                        <TextWithCopy text={systemId}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transaction ID:</TableCell>
                                    <TableCell>
                                        <Chip label={"Block "+data.block.blockHeader.blockNumber}/>
                                        <Chip label={"Index "+data.block.transactionIndex}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transaction request ID:</TableCell>
                                    <TableCell><TextWithCopy text={data.transaction.transactionId}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transaction timestamp:</TableCell>
                                    <TableCell>{data.block.blockHeader.endTime}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transaction type:</TableCell>
                                    <TableCell>{data.transaction.request?.type}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Trust anchors:</TableCell>
                                    <TableCell>
                                        { Object.values( getVerifiedTrustAnchors( data ) ).map( a => <b><TextWithCopy text={a}/></b> )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <HoldingsTable bakedHoldings={bakedHoldings} issuedHoldings={data.transaction.issuedHoldings} content={content}/>
                    </Grid>
                    { data.transaction.request &&
                        <Grid item xs={12}>
                            <h4>Request details</h4>
                            <pre>{JSON.stringify( data.transaction.request, 0, 2 )}</pre>
                        </Grid>}
                </Grid>
            </Box>
        </Box>
    </ThemeProvider>
}

function HoldingsTable( { bakedHoldings, issuedHoldings, content } )
{
    return (<TableContainer>
        <Table size="small">
            <TableHead>
                <TableCell colSpan={2}>Product item</TableCell>
                <TableCell sx={{width: 100, maxWidth:100, overflow:'wrap'}} align="right">Holding ID</TableCell>
                <TableCell sx={{width: 100, maxWidth:100, overflow:'wrap'}} align="right">From index</TableCell>
                <TableCell sx={{width: 5, maxWidth:5, overflow:'wrap'}}/>
                <TableCell sx={{width: 100, maxWidth:100, overflow:'wrap'}}>To index</TableCell>
                <TableCell sx={{width: 150, maxWidth:150, overflow:'wrap'}} colSpan={2}>Amount</TableCell>
            </TableHead>
            <TableBody>
                { bakedHoldings?.productIds.map( productId => <Fragment><TableRow>
                        <TableCell colspan={7}>
                            <h4>Retired {getProductData( content, productId ).code} holdings <ProductChip productId={productId}/></h4>
                        </TableCell>
                    </TableRow>
                    <HoldingsForProduct
                            holdings={bakedHoldings.holdings.filter( h => h.productId===productId)}
                            attributeTypes={content.attributeTypes}
                    />
                    </Fragment>
                )}
                { issuedHoldings?.map( accountHoldings => {
                    const holdings= organizeHoldings( content, accountHoldings.holdings )

                    return holdings.productIds.map( productId => {
                        const productData= getProductData( content, productId )
                        return <Fragment><TableRow>
                            <TableCell colspan={7}>
                                <h4>Issued {productData.code} holdings for account {accountHoldings.accountId}</h4>
                            </TableCell></TableRow>
                            <HoldingsForProduct
                                    holdings={holdings.holdings.filter( h=>h.productId===productId)}
                                    attributeTypes={content.attributeTypes} />
                        </Fragment>
                    })
                } )}
            </TableBody>
        </Table></TableContainer>
    )
}


/**
 * Show a table for holdings of an account/product.
 * @param holdings
 * @returns {JSX.Element}
 * @constructor
 */
function HoldingsForProduct( { holdings, attributeTypes } )
{
    // collect the product items that were retired
    let productItemIds= []
    Object.values( holdings ).forEach( h => { if( productItemIds.indexOf( h.productItem )<0 ) productItemIds.push( h.productItem ) } )

    // sum the holdings
    let totalAmount= Big(0)
    holdings.forEach( h => {
        totalAmount= totalAmount.plus( Big(h.quantity).mul(Big(h.productItemData.unitAmount)) )
    } )

    return <Fragment>
            {Object.values( productItemIds ).map( (productItemId, index) => {
                const itemHoldings= Object.values( holdings ).filter( h => h.productItem===productItemId )
                return <Fragment key={index}>
                    {itemHoldings.map( (h, index) => <TableRow key={index}>
                        {index===0 && <Fragment>
                            <TableCell rowSpan={itemHoldings.length}><ProductItemChip productItemId={productItemId}/></TableCell>
                            <TableCell rowSpan={itemHoldings.length}><AttributeValueTable attributes={h.productItemData?.attributes} attributeTypes={attributeTypes}/></TableCell>
                        </Fragment>}
                        <TableCell align="right">{h.id || h.holdingId}</TableCell>
                        <TableCell align="right"><i>{h.index}</i></TableCell>
                        <TableCell sx={{maxWidth:'5px'}}>...</TableCell>
                        <TableCell><i>{h.index+h.quantity-1}</i></TableCell>
                        <TableCell align="right"><ProductAmount amount={Big(h.quantity).mul(Big(h.productItemData.unitAmount))} productData={h.productData} /></TableCell>
                        <TableCell>{h.productData.code}</TableCell>
                    </TableRow>)}
                </Fragment>
            })}

            <TableRow>
                <TableCell colSpan={5}/>
                <TableCell align="right">TOTAL</TableCell>
                <TableCell align="right"><b><ProductAmount amount={totalAmount} productData={holdings[0].productData}/></b></TableCell>
                <TableCell><b>{holdings[0].productData.code}</b></TableCell>
            </TableRow>
    </Fragment>
}


//
//  COMPONENTS
//

function ProductChip( {productId} ) {
    return <Chip variant="outlined" label={"Product "+productId} />
}

function ProductItemChip( {productItemId} ) {
    return <Chip variant="outlined" label={"Item "+productItemId} />
}

export function AttributeValue( {name, value, attributeTypes} )
{
    const type= attributeTypes[name]

    switch( type )
    {
    case "URI":
        return <a href={value} target="_blank" rel="noreferrer" style={{whiteSpace:'nowrap', overflow:'hidden'}}>
            <FontAwesomeIcon icon={faExternalLinkAlt}/>&nbsp;
            {value}
        </a>

    case "DOCUMENT":
        return <span><div style={{display:'inline-block'}}><DocumentIcon data={value}/></div>
            <small>{value.sha256Hash.substring( 0, value.sha256Hash.length/2 )
                    +" "
                    +value.sha256Hash.substring( value.sha256Hash.length/2 )}</small>
        </span>

    default:
        return <span>{value}</span>
    }
}

export function DocumentIcon( {data, size} )
{
    switch( data.contentType )
    {
    case "application/pdf":
        return <FontAwesomeIcon icon={faFilePdf} size={size}/>

    case "image/png":
        return <FontAwesomeIcon icon={faFileImage} size={size}/>

    default:
        return <FontAwesomeIcon icon={faFile} size={size}/>
    }
}

function AttributeValueTable( {attributes, attributeTypes} ) {
     // return <div style={{display: 'inline-block'}} >
     //     {attributes && Object.entries( attributes ).map( (e, index) => <AttributeValue key={index} name={e[0]} value={e[1]}/>)}
     // </div>

    return <Table size='small'>
        <TableBody>
            {attributes && Object.entries( attributes ).map( (e, index) => <TableRow key={index} xs={{}}>
                <CompactLabelCell style={{border:"none"}} align="right"><small>{e[0]}</small></CompactLabelCell>
                <CompactTableCell style={{border:"none"}}><AttributeValue name={e[0]} value={e[1]} attributeTypes={attributeTypes}/></CompactTableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}


function TextWithCopy( {text} )
{
    return <Typography fontSize={12} style={{whiteSpace:'nowrap'}}>
        {text}
        <IconButton size="small" sx={{color: 'primary.main'}} onClick={()=>navigator.clipboard.writeText( text )}>
            <FontAwesomeIcon icon={faCopy}/>
        </IconButton>
    </Typography>
}

const CompactTableCell= styled( TableCell )({
    paddingTop: 1, paddingBottom: 1, border: 'none', overflow: 'wrap'
})

const CompactLabelCell= styled( TableCell )({
    paddingTop: 1, paddingBottom: 1, border: 'none', minWidth: 100, maxWidth: 100, overflow: 'wrap'
})


//
//  UTILITY METHODS
//

/**
 * Sort holdings by productItemId and starting index
 * @param x
 * @param y
 */
function compareHolding( x, y )
{
    const productItemIdDiff= compareBlockAndIndex( x.productItem, y.productItem )
    if( productItemIdDiff!==0 ) {
        return productItemIdDiff
    }

    return x.index-y.index
}

/**
 * Compare a 'block:index' string numerically.
 * @param x
 * @param y
 * @returns {number}
 */
function compareBlockAndIndex( x, y )
{
    const xparts= splitBlockAndIndex( x )
    const yparts= splitBlockAndIndex( y )

    if( xparts[0]!==yparts[0] ) return xparts[0]-yparts[0]
    return xparts[1]-yparts[1]
}

function splitBlockAndIndex( x )
{
    const parts= x.split( ":" )
    return [ parseInt(parts[0]), parseInt(parts[1]) ]
}


/**
 * Sort through the list of holdings and organize them by product, product item id and index
 * @param content
 * @param holdings
 */
function organizeHoldings( content, holdings )
{
    let productIds= []

    const newHoldings= holdings.map( h => {
        const productItemData= getProductItemData( content, h.productItem )
        const productId= productItemData.productId
        if( productIds.indexOf( productId )<0 ) productIds.push( productId )

        const productData= getProductData( content, productId )

        return { ...h, productId, productData, productItemData }
    })

    newHoldings.sort( compareHolding )
    productIds.sort( compareBlockAndIndex )
    return { productIds, holdings: newHoldings }
}

function getProductData( content, productId )
{
    return content.products.find( p => p.productId===productId ).data
}

function getProductItemData( content, productItemId )
{
    return content.productItems.find( p => p.productItemId===productItemId ).data
}


function getVerifiedTrustAnchors( data ) {
    let result= []
    for( const s of data.block.blockSignatures )
    {
        for( const a of s.trustAnchors )
        {
            if( result.indexOf( a.keyId )<0 ) {
                result.push( a.keyId )
            }
        }
    }
    return result
}
